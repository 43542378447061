import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { WebgisService } from './../webgis.service';
import { MeasurementService } from './../logic/measurement.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare var google: any;

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit {
  annotation: any;
  color: string;
  debug = false;
  debugVertex = false;
  objectKeys = Object.keys;
  uID: number;

  constructor(
    private API: ApiService,
    private route: ActivatedRoute,
    private webgisService: WebgisService,
    private router: Router,
    private measurementService: MeasurementService
  ) { }

  changeColor(r, g, b): void {
    this.color = `rgb(${r},${g},${b})`;
    // API
    this.API.changeColor(this.uID, r, g, b).subscribe();
    if (this.annotation['type'] === 'Marker' || this.annotation['type'] === 'ObjMarker') {
      const markerImage = {
        path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
        anchor: new google.maps.Point(12, 23),
        fillOpacity: 1,
        fillColor: this.color,
        strokeWeight: 2,
        strokeColor: 'white',
        scale: 2
      };
      this.annotation.setOptions({icon: markerImage});
    } else if (this.annotation['type'] === 'Polyline') {
      this.annotation.setOptions({strokeColor: this.color});
    } else if (this.annotation['type'] === 'Polygon') {
      this.annotation.setOptions({fillColor: this.color, strokeColor: this.color});
    } else if (this.annotation['type'] === 'Object') {
      this.annotation.height.setOptions({fillColor: this.color, strokeColor: this.color});
      this.annotation.width.setOptions({fillColor: this.color, strokeColor: this.color});
      this.annotation.length.setOptions({fillColor: this.color, strokeColor: this.color});
    }
  }

  changeComment(): void {
    this.API.changeComment(this.uID, this.annotation.comment).subscribe();
  }

  changeName(): void {
    this.API.changeName(this.uID, this.annotation.name).subscribe();
  }

  changeObjHeight(): void {
    this.API.changeObjHeight(this.uID, +this.annotation.objHeight).subscribe();
    this.annotation.objVolume = Math.round(this.annotation.objLength * this.annotation.objWidth * this.annotation.objHeight * 1000) / 1000;

  }

  changeObjLength(): void {
    this.API.changeObjLength(this.uID, this.annotation.objLength).subscribe();
    this.annotation.objVolume = Math.round(this.annotation.objLength * this.annotation.objWidth * this.annotation.objHeight * 1000) / 1000;
  }

  changeObjWidth(): void {
    this.API.changeObjWidth(this.uID, this.annotation.objWidth).subscribe();
    this.annotation.objVolume = Math.round(this.annotation.objLength * this.annotation.objWidth * this.annotation.objHeight * 1000) / 1000;
  }
  copyURL(): any {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.annotation.report;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  deleteAnnotation(): void {
    const check = confirm('Do you want to delete "' + this.annotation.name + '" annotation?');
    if (check === true) {
      // toDo Pop selected uID
      this.API.deleteEntrie(this.uID).subscribe();
      if (this.annotation.type === 'Object') {
        this.annotation.height.setMap(null);
        this.annotation.length.setMap(null);
        this.annotation.width.setMap(null);

      } else {
        this.annotation.setMap(null);
      }
      this.webgisService.popAnnotation(this.uID);
      this.router.navigate(['', { outlets: { detail: ['toc'] } }]);
    }
  }

  downloadAnnotationCSV(): void {
    this.webgisService.downloadAnnotationCSV(this.annotation);
  }

  downloadAnnotationGeoJSON(): void {
    this.webgisService.downloadAnnotationGeoJSON(this.annotation);
  }

  editObject(part?): void {
    if (part === 'height') {
      this.annotation.height.setEditable(!this.annotation.height.getEditable());
      if (this.annotation.width.editable) {
        this.annotation.width.setEditable(!this.annotation.width.getEditable());
      }
      if (this.annotation.length.editable) {
        this.annotation.length.setEditable(!this.annotation.length.getEditable());
      }
    } else if (part === 'width') {
      this.annotation.width.setEditable(!this.annotation.width.getEditable());
      if (this.annotation.height.editable) {
        this.annotation.height.setEditable(!this.annotation.height.getEditable());
      }
      if (this.annotation.length.editable) {
        this.annotation.length.setEditable(!this.annotation.length.getEditable());
      }
    } else if (part === 'length') {
      this.annotation.length.setEditable(!this.annotation.length.getEditable());
      if (this.annotation.height.editable) {
        this.annotation.height.setEditable(!this.annotation.height.getEditable());
      }
      if (this.annotation.width.editable) {
        this.annotation.width.setEditable(!this.annotation.width.getEditable());
      }
    }
    if (part === undefined && this.annotation.height !== undefined && this.annotation.width !== undefined && this.annotation.length !== undefined) {
      if (this.annotation.height.editable) {
        this.annotation.height.setEditable(!this.annotation.height.getEditable());
      }
      if (this.annotation.width.editable) {
        this.annotation.width.setEditable(!this.annotation.width.getEditable());
      }
      if (this.annotation.length.editable) {
        this.annotation.length.setEditable(!this.annotation.length.getEditable());
      }
    }
  }

  // used ngDoCheck and not ngInit() to switch while klicking features on map
  ngAfterContentChecked(): void {
    if (this.uID !== +this.route.snapshot.paramMap.get('_id')) {
      this.toggleEditable();

      this.uID = +this.route.snapshot.paramMap.get('_id');
      this.annotation = this.webgisService.getAnnotation(this.uID);

      if (this.annotation === 'noAnnotation') {
        this.router.navigate(['', { outlets: { detail: ['toc'] } }]);
      } else if (this.annotation['type'] === 'Marker' || this.annotation['type'] === 'ObjMarker') {

        this.measurementService.getHeightMarker(this.annotation);
        this.color = this.annotation.icon.fillColor;
      } else if (this.annotation['type'] === 'Object') {
        this.color = this.annotation.height.strokeColor;
      } else {
        this.color = this.annotation.strokeColor;
      }
      this.toggleEditable();
    }
  }

  ngOnDestroy(): void {
    this.editObject();
    this.toggleEditable();
  }

  ngOnInit(): void {
    this.uID = +this.route.snapshot.paramMap.get('_id');
    this.annotation = this.webgisService.getAnnotation(this.uID);
    if (this.annotation === 'noAnnotation') {
      this.router.navigate(['', { outlets: { detail: ['toc'] } }]);
    } else if (this.annotation['type'] === 'Marker' || this.annotation['type'] === 'ObjMarker') {

      this.measurementService.getHeightMarker(this.annotation);
      this.color = this.annotation.icon.fillColor;
    } else if (this.annotation['type'] === 'Object') {
      this.color = this.annotation.height.strokeColor;
    } else {
      this.color = this.annotation.strokeColor;
    }
    this.toggleEditable();
  }

  toggleEditable(): void {
    if (this.annotation['type'] === 'Marker') {
      this.annotation.setDraggable(!this.annotation.getDraggable());
      if (this.annotation.getDraggable()) {
        this.annotation.setAnimation(google.maps.Animation.BOUNCE);
      } else {
        this.annotation.setAnimation(null);
      }
    } else if (this.annotation['type'] === 'Polygon' || this.annotation['type'] === 'Polyline') {
    this.annotation.setEditable(!this.annotation.getEditable());
    }
  }

  openReport(): void {
    window.open(this.annotation.report);
  }

}
