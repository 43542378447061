import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent {
  // Source: https://stackoverflow.com/questions/38037760/how-to-set-iframe-src-in-angular-2-without-causing-unsafe-value-exception
  @Input()
  url: string;
  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private router: Router
    ) {
      this.urlChanged();
      this.router.events.subscribe(path => {
        this.urlChanged();
      });
    }

  urlChanged(): void {
    const route = this.router.url.split('(')[0].split('/')[2].toLowerCase();
    if (route === '4190') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190.html";
    } else if (route === '4190-insel1') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel1.html";
    } else if (route === '4190-insel2') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel2.html";
    } else if (route === '4190-insel3') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel3.html";
    } else if (route === '4190-insel4') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel4.html";
    } else if (route === '4190-insel5') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel5.html";
    } else if (route === '3587') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587.html";
    } else if (route === '3587-insel1') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel1.html";
    } else if (route === '3587-insel2') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel2.html";
    }  else if (route === '3587-insel3') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel3.html";
    }  else if (route === '3587-insel4') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel4.html";
    }  else if (route === '3587-insel5') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel5.html";
    }  else if (route === '3587-insel6') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel6.html";
    }  else if (route === '3587-insel7') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel7.html";
    }  else if (route === '3587-insel8') {
      this.url = "https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel8.html";
    } else {
      //this.router.navigate(['/webgis/4190']);
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }
}
