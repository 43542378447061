// https://www.positronx.io/angular-7-httpclient-http-service/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // Define API
  apiURL = 'https://kunden.locvis.de/ff/api_v2.php';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  addLine(flightid, inputUid, inputName): Observable<any> {
    return this.http.put<any>(this.apiURL+ '?flightid=' + flightid, `{"action":"addLine", "uid":${inputUid}, "name":"${inputName}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addMarker(flightid, inputUid, inputName, inputCoordinates): Observable<any> {
    return this.http.put<any>(this.apiURL + '?flightid=' + flightid, `{"action":"addMarker", "uid":${inputUid}, "name":"${inputName}", "coordinates":"${inputCoordinates}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addObject(inputUid, inputName): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"addObject", "uid":${inputUid}, "name":"${inputName}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  addPolygon(flightid, inputUid, inputName): Observable<any> {
    return this.http.put<any>(this.apiURL + '?flightid=' + flightid, `{"action":"addPolygon", "uid":${inputUid}, "name":"${inputName}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeColor(inputUid, r, g, b): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeColor", "uid":${inputUid}, "color":"rgb(${r},${g},${b})"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeComment(inputUid, comment): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeComment", "uid":${inputUid}, "comment":"${comment}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeCoordinates(inputUid, coordinates): Observable<any> {
    coordinates = "[" + coordinates + "]";
    return this.http.put<any>(this.apiURL, `{"action":"changeCoordinates", "uid":${inputUid}, "coordinates":"${coordinates}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeName(inputUid, name): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeName", "uid":${inputUid}, "name":"${name}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeObject(inputUid, paths, objMeasurment): any { // Observable<any> {
    let stringPath = '[';
    // tslint:disable-next-line: forin
    for (const keyPaths in paths) {
      stringPath += '[';
      // tslint:disable-next-line: forin
      for (const keyPath in paths[keyPaths]) {
        stringPath += `[${paths[keyPaths][keyPath].lng},${paths[keyPaths][keyPath].lat},${paths[keyPaths][keyPath].alt}],`;
      }
      stringPath = stringPath.substring(0, stringPath.length - 1);

      stringPath += '],';
    }
    stringPath = stringPath.substring(0, stringPath.length - 1);
    stringPath += ']';
    this.changeObjLength(inputUid, +objMeasurment[0]).subscribe();
    this.changeObjWidth(inputUid, +objMeasurment[1]).subscribe();
    this.changeObjHeight(inputUid, +objMeasurment[2]).subscribe();

    return this.http.put<any>(this.apiURL, `{"action":"changeCoordinates", "uid":${inputUid}, "coordinates":"${stringPath}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeObjHeight(inputUid, height): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeObjValue", "uid":${inputUid}, "key":"objHeight", "value":"${height}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  changeObjLength(inputUid, height): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeObjValue", "uid":${inputUid}, "key":"objLength", "value":"${height}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  changeObjWidth(inputUid, height): Observable<any> {
    return this.http.put<any>(this.apiURL, `{"action":"changeObjValue", "uid":${inputUid}, "key":"objWidth", "value":"${height}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changePath(inputUid, path): Observable<any> {
    let stringPath = '[';

    // tslint:disable-next-line: forin
    for (const key in path) {
      stringPath += `[${path[key].lng},${path[key].lat},${path[key].alt}],`;
    }
    stringPath = stringPath.substring(0, stringPath.length - 1);
    stringPath += ']';

    return this.http.put<any>(this.apiURL, `{"action":"changeCoordinates", "uid":${inputUid}, "coordinates":"${stringPath}"}`)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteEntrie(inputUid): Observable<any> {
    return this.http.get<any>(this.apiURL + '?action=delete&uid=' + inputUid)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getEntries(flightid): Observable<any> {
    return this.http.get<any>(this.apiURL+ '?flightid=' + flightid)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  resetEntries(flightid): Observable<any> {
    return this.http.get<any>(this.apiURL + '?action=reset&flightid=' + flightid)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
 }
}
