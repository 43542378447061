import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../webgishelper/mock-service/api.service';
import { mongoMap } from './../../webgishelper/mock-service/json/map';
import { WebgisService } from './../webgis.service';

@Component({
  selector: 'app-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss']
})
export class TocComponent implements OnInit {
  actionEvent: any;
  actionEventLast: any;
  dem: string;
  demDisabled: boolean;
  flightid: any;
  toc: Array<any>;
  webgis = false;
  pointcloud: any;
  inseln: any;

  constructor(
      private API: ApiService,
      private webgisService: WebgisService,
      private router: Router
    ) {
    this.webgisService.actionService.subscribe(action => this.toggleAction(action));
    this.webgisService.demService.subscribe(dem => this.dem = dem);
    this.webgisService.toc$.subscribe(toc => this.toc = toc);
    if (this.dem.length > 0) {
      this.demDisabled = false;
    } else {
      this.demDisabled = true;
    }
    const route = this.router.url;
    if (route.split('(')[0].split('/')[1].toLowerCase() === 'webgis') {
      this.webgis = true;
    }
  }
  downloadAnnotationsCSV(): void {
    this.webgisService.downloadAnnotationsCSV(this.toc);
  }

  downloadAnnotationsGeoJSON(): void {
    this.webgisService.downloadAnnotationsGeoJSON(this.toc);
  }

  drawMarker(event: any): void {
    this.actionEvent = event;
    this.webgisService.updateAction('drawMarker');
  }

  drawObject(event: any): void {
    this.actionEvent = event;
    this.webgisService.updateAction('drawObject');
  }

  drawPolygon(event: any): void {
    this.actionEvent = event;
    this.webgisService.updateAction('drawPolygon');
  }

  drawPolyline(event: any): void {
    this.actionEvent = event;
    this.webgisService.updateAction('drawPolyline');
  }

  ngOnInit(): void {
    const tmpFlightid = this.router.url.split('(')[0].split('/')[2];
    if (tmpFlightid.includes('-')) {
      this.flightid = tmpFlightid.split('-')[0];
    } else {
      this.flightid = tmpFlightid;
    }
    if (!(this.flightid === '4190' || this.flightid === '3587')) {
      alert('No Project found');
      this.router.navigate(['']);
    }
    try {
      this.pointcloud = mongoMap[this.flightid]['pointCloud'];
    } catch (e) {
      this.pointcloud = '';
    }
    this.inseln = mongoMap[this.flightid]['pointCloudInsel'];

    /*const studentsObservable = this.webgisService.getToc(); // this.studentservice.getStudents();
    studentsObservable.subscribe(toc => this.toc = toc);*/
  }

  resetAnnotations(): void {
    const check = confirm('Do you want to reset all annotation?');
    if (check === true) {
      this.API.resetEntries(this.flightid).subscribe((data: any) => {
        window.location.reload();
      });
    }
  }

  toggleAction(action: string): void {
    if (this.actionEvent !== undefined) {
      if (action === '') {
        this.toggleActiveClass(this.actionEventLast);
      } else {
        this.toggleActiveClass(this.actionEvent);
      }
    }
    this.actionEventLast = this.actionEvent;
  }

  toggleActiveClass(event: any): void {
    if (event.target.children.length === 0) {
      event.target.classList.toggle('active');
    } else {
      event.target.children[0].children[0].classList.toggle('active');
    }
    this.actionEventLast = event;
  }

  toggleDEM(event: any): void {
    /*
    if (event.target.children.length === 0) {
      event.target.classList.toggle('active');
    } else {
      event.target.children[0].children[0].classList.toggle('active');
    } */
    if (this.dem.length > 0) {
      if (this.dem === 'addDEM') {
        this.webgisService.updateDEM('');
        this.demDisabled = true;
      } else {
        this.webgisService.updateDEM('addDEM');
        this.demDisabled = false;
      }
    } else {
      this.webgisService.updateDEM('addDEM');
      this.demDisabled = false;
    }
  }
  toggleMain(main): void {
    if (main.toLowerCase() === 'webgis') {
      this.webgis = true;
    } else {
      this.webgis = false;
    }
  }

  updateDemOpacity(event: any): void {
    this.webgisService.updateDemOpacity(event.value);
  }
}
