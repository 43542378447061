import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TocComponent } from './pages/webgis/toc/toc.component';
import { WebgisComponent } from './pages/webgis/webgis.component';
import { AnnotationComponent } from './pages/webgis/annotation/annotation.component';
import { ViewerComponent } from './pages/potree/viewer/viewer.component';

const appRoutes: Routes = [
  {
    path: 'webgis',
    component: WebgisComponent
  },
  {
    path: 'webgis/:_id',
    component: WebgisComponent
  },
  {
    path: 'pointcloud/:_id',
    component: ViewerComponent
  },
  {
    path: 'toc',
    component: TocComponent,
    outlet: 'detail'
  },
  {
    path: 'annotation/:_id',
    component: AnnotationComponent,
    outlet: 'detail'
  }
];

@NgModule({
imports: [
    RouterModule.forRoot(
    appRoutes
    )
],
exports: [
    RouterModule
]
})

export class AppRoutingModule { }
