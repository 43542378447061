export const mongoMap = {
  4190: {
    _id: '123456789012345678901234',
    extent: {
        xmin: 7.104637240371858,
        ymin: 50.819963047563945,
        xmax: 7.105148201189195,
        ymax: 50.82020027841392
    },
    geoserver: {
      serverURL: 'https://geoserver.mikewunderlich.de/',
      // serverURL: 'http://195.128.101.62:8888/geoserver/ows?',
      workspace: 'ff-4190ff',
      dem: 'DSM',
      ortho: 'ortho'
    },
    pointCloud: {
      name: '4190',
      url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190.html'
    },
    pointCloudInsel: [
      {name: '4190-insel1', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel1.html'},
      {name: '4190-insel2', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel2.html'},
      {name: '4190-insel3', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel3.html'},
      {name: '4190-insel4', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel4.html'},
      {name: '4190-insel5', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff4190insel5.html'}
    ]
  },
  3587: {
    _id: '123456789012345128901234',
    extent: {
        xmin: 55.09727776080888,
        ymin: 24.964272694760563,
        xmax: 55.098514259164745,
        ymax: 24.965111598482743
    },
    geoserver: {
      serverURL: 'https://geoserver.mikewunderlich.de/',
      // serverURL: 'http://195.128.101.62:8888/geoserver/ows?',
      workspace: 'ff-3587',
      dem: 'ff3587dsm',
      ortho: 'ff3587ortho_export'
    },
    pointCloud: {
      name: '3587',
      url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587.html'
    },
    pointCloudInsel: [
      {name: '3587-insel1', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel1.html'},
      {name: '3587-insel2', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel2.html'},
      {name: '3587-insel3', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel3.html'},
      {name: '3587-insel4', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel4.html'},
      {name: '3587-insel5', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel5.html'},
      {name: '3587-insel6', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel6.html'},
      {name: '3587-insel7', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel7.html'},
      {name: '3587-insel8', url: 'https://kunden.locvis.de/DatenTmp/pointcloud/ff3587insel8.html'}
    ]
  }

};
