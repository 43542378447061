import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { feature } from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class WebgisService {
  // ToDo like toc$
  action = new BehaviorSubject('');
  actionService = this.action.asObservable();
  dem = new BehaviorSubject('');
  demOpacity$ = new BehaviorSubject(50);
  demService = this.dem.asObservable();
  map: any;
  toc$: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  private _todos: BehaviorSubject<Array<string>>;

  constructor() {
    this._todos = new BehaviorSubject([]) as BehaviorSubject<Array<string>>;
  }
  get todos(): Observable<Array<string>> {
    return this._todos.asObservable();
  }

  downloadAnnotationCSV(annotations): void {
    // WIP
  }

  downloadAnnotationGeoJSON(annotation): void {
    const exportJSON = {'type': 'Feature'};
    exportJSON['properties'] = {'name': annotation.name, 'comment': annotation.comment};
    if (annotation.type === 'Marker') {
      exportJSON['geometry'] = {
      'coordinates': [+annotation.position.lng(), +annotation.position.lat(), +annotation.height],
      'type': 'Point'};
      exportJSON['properties']['LocationHeight'] = annotation.height;
      exportJSON['properties']['ObjectLength'] = annotation.objLength;
      exportJSON['properties']['ObjectWidth'] = annotation.objWidth;
      exportJSON['properties']['ObjectHeight'] = annotation.objHeight;
      exportJSON['properties']['ObjectVolume'] = annotation.objVolume;
    } else if (annotation.type === 'ObjMarker') {
      exportJSON['geometry'] = {
      'coordinates': [annotation.position.lng(), annotation.position.lat()],
      'type': 'Point'};
      exportJSON['properties']['length'] = annotation.objLength;
      exportJSON['properties']['width'] = annotation.objWidth;
      exportJSON['properties']['height'] = annotation.objHeight;
      exportJSON['properties']['volume'] = annotation.objVolume;
    } else if (annotation.type === 'Polyline') {
      exportJSON['geometry'] = {
        'coordinates': [],
        'type': 'LineString'};
      // tslint:disable-next-line:forin
      for (const coord in annotation.path) {
        exportJSON['geometry']['coordinates'].push([annotation.path[coord].lng,
          annotation.path[coord].lat, annotation.path[coord].alt]);
      }
      exportJSON['properties']['length2D'] = annotation.length2D;
      exportJSON['properties']['length3D'] = annotation.length3D;
    } else if (annotation.type === 'Polygon') {
      exportJSON['geometry'] = {
        'coordinates': [[]],
        'type': 'Polygon'};
      // tslint:disable-next-line:forin
      for (const coord in annotation.path) {
        exportJSON['geometry']['coordinates'][0].push([annotation.path[coord].lng,
          annotation.path[coord].lat, annotation.path[coord].alt]);
      }
      exportJSON['geometry']['coordinates'][0].push([annotation.path[0].lng,
        annotation.path[0].lat, annotation.path[0].alt]);
      exportJSON['properties']['length2D'] = annotation.length2D;
      exportJSON['properties']['length3D'] = annotation.length3D;
      exportJSON['properties']['area2D'] = annotation.area2D;
      exportJSON['properties']['area3D'] = annotation.area3D;
    } else if (annotation.type === 'Object') {
      exportJSON['geometry'] = {
        'coordinates': [[],[],[]],
        'type': 'MultiLineString'};
      // tslint:disable-next-line:forin
      for (const coord in annotation.length.path) {
        exportJSON['geometry']['coordinates'][0].push([annotation.length.path[coord].lng,
          annotation.length.path[coord].lat, annotation.length.path[coord].alt]);
      }
      // tslint:disable-next-line:forin
      for (const coord in annotation.width.path) {
        exportJSON['geometry']['coordinates'][1].push([annotation.width.path[coord].lng,
          annotation.width.path[coord].lat, annotation.width.path[coord].alt]);
      }
      // tslint:disable-next-line:forin
      for (const coord in annotation.height.path) {
        exportJSON['geometry']['coordinates'][2].push([annotation.height.path[coord].lng,
          annotation.height.path[coord].lat, annotation.height.path[coord].alt]);
      }
      exportJSON['properties']['length'] = annotation.length.length3D;
      exportJSON['properties']['width'] = annotation.width.length3D;
      exportJSON['properties']['height'] = annotation.height.heightDiff;
      exportJSON['properties']['volume'] = annotation.volume;
    }

    const sJson = JSON.stringify(exportJSON);
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/json;charset=UTF-8,${encodeURIComponent(sJson)}`);
    element.setAttribute('download', `${annotation.name}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  downloadAnnotationsCSV(annotations): void {
    const CSVHeader = 'Identifier;Comment;Type;L;W;H;CBM \n';

    let CSVContent = '';
    
    // tslint:disable-next-line:forin
    for (const index in annotations) {
        const annotation = annotations[index];
        if (annotation.type === 'Marker') {
          let comment;
          if (typeof annotation.comment !== 'undefined') {
            comment = annotation.comment;
          } else {
            comment = '';
          }
          let strLength = "" + annotation.objLength;
          let strWidth = "" + annotation.objWidth;
          let strHeight = "" + annotation.objHeight;
          let strVolume = "" + annotation.objVolume;
          strLength = strLength.replace('.', ',');
          strWidth = strWidth.replace('.', ',');
          strHeight = strHeight.replace('.', ',');
          strVolume = strVolume.replace('.', ',');
          CSVContent +=  `"${annotation.name}";"${comment}";"Point";"${strLength}";"${strWidth}";"${strHeight}";"${strVolume}"\n`;
        } else if (annotation.type === 'Object') {
          let comment;
          if (typeof annotation.comment !== 'undefined') {
            comment = annotation.comment;
          } else {
            comment = '';
          }
          let strLength = "" + annotation.length.length3D;
          let strWidth = "" + annotation.width.length3D;
          let strHeight = "" + annotation.height.heightDiff;
          let strVolume = "" + annotation.volume;
          strLength = strLength.replace('.', ',');
          strWidth = strWidth.replace('.', ',');
          strHeight = strHeight.replace('.', ',');
          strVolume = strVolume.replace('.', ',');
          CSVContent +=  `"${annotation.name}";"${comment}";"Object";"${strLength}";"${strWidth}";"${strHeight}";"${strVolume}"\n`;
        }
    }

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/json;charset=UTF-8,${encodeURIComponent(CSVHeader + CSVContent)}`);
    element.setAttribute('download', `Annotations.csv`);
    //element.setAttribute('download', `${annotation.name}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);

  }

  downloadAnnotationsGeoJSON(annotations): void {
    const exportJSON = {'type': 'FeatureCollection', "features": []};
    // tslint:disable-next-line:forin
    for (const index in annotations) {
      const annotation = annotations[index];
      const featureJSON = {"type": "Feature"};
      featureJSON['properties'] = {'name': annotation.name, 'comment': annotation.comment};
      if (annotation.type === 'Marker') {
        featureJSON["geometry"] = {"coordinates": [+annotation.position.lng(), +annotation.position.lat(), +annotation.height],
        "type": "Point"};
        featureJSON['properties']['LocationHeight'] = annotation.height;
        featureJSON['properties']['ObjectLength'] = annotation.objLength;
        featureJSON['properties']['ObjectWidth'] = annotation.objWidth;
        featureJSON['properties']['ObjectHeight'] = annotation.objHeight;
        featureJSON['properties']['ObjectVolume'] = annotation.objVolume;
        exportJSON.features.push(featureJSON);
      }
      if (annotation.type === 'ObjMarker') {
        featureJSON["geometry"] = {"type": "Point",
            "coordinates": [annotation.position.lng(), annotation.position.lat()]};
          featureJSON['properties']['length'] = annotation.objLength;
          featureJSON['properties']['width'] = annotation.objWidth;
          featureJSON['properties']['height'] = annotation.objHeight;
          featureJSON['properties']['volume'] = annotation.objVolume;
        exportJSON.features.push(featureJSON);
      }
      if (annotation.type === 'Polyline') {
        featureJSON['geometry'] = {
          'coordinates': [],
          'type': 'LineString'};
        // tslint:disable-next-line:forin
        for (const coord in annotation.path) {
          featureJSON['geometry']['coordinates'].push([annotation.path[coord].lng,
            annotation.path[coord].lat, annotation.path[coord].alt]);
        }
        featureJSON['properties']['length2D'] = annotation.length2D;
        featureJSON['properties']['length3D'] = annotation.length3D;
        exportJSON.features.push(featureJSON);
      }
      if (annotation.type === 'Polygon') {
        featureJSON['geometry'] = {
          'coordinates': [[]],
          'type': 'Polygon'};
        // tslint:disable-next-line:forin
        for (const coord in annotation.path) {
          featureJSON['geometry']['coordinates'][0].push([annotation.path[coord].lng,
            annotation.path[coord].lat, annotation.path[coord].alt]);
        }
        featureJSON['geometry']['coordinates'][0].push([annotation.path[0].lng,
          annotation.path[0].lat, annotation.path[0].alt]);
        featureJSON['properties']['length2D'] = annotation.length2D;
        featureJSON['properties']['length3D'] = annotation.length3D;
        featureJSON['properties']['area2D'] = annotation.area2D;
        featureJSON['properties']['area3D'] = annotation.area3D;
        exportJSON.features.push(featureJSON);
      } else if (annotation.type === 'Object') {
        featureJSON['geometry'] = {
          'coordinates': [[],[],[]],
          'type': 'MultiLineString'};
        // tslint:disable-next-line:forin
        for (const coord in annotation.length.path) {
          featureJSON['geometry']['coordinates'][0].push([annotation.length.path[coord].lng,
            annotation.length.path[coord].lat, annotation.length.path[coord].alt]);
        }
        // tslint:disable-next-line:forin
        for (const coord in annotation.width.path) {
          featureJSON['geometry']['coordinates'][1].push([annotation.width.path[coord].lng,
            annotation.width.path[coord].lat, annotation.width.path[coord].alt]);
        }
        // tslint:disable-next-line:forin
        for (const coord in annotation.height.path) {
          featureJSON['geometry']['coordinates'][2].push([annotation.height.path[coord].lng,
            annotation.height.path[coord].lat, annotation.height.path[coord].alt]);
        }
        featureJSON['properties']['length'] = annotation.length.length3D;
        featureJSON['properties']['width'] = annotation.width.length3D;
        featureJSON['properties']['height'] = annotation.height.heightDiff;
        featureJSON['properties']['volume'] = annotation.volume;
        exportJSON.features.push(featureJSON);
      }
    }
    const sJson = JSON.stringify(exportJSON);
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/json;charset=UTF-8,${encodeURIComponent(sJson)}`);
    element.setAttribute('download', `Annotations.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  getAnnotation(uID): any {
    // ToDo Philip better solution?
    let output = 'noAnnotation';
    this.toc$.value.forEach(value => {
      if (value['uID'] === uID) {
        output = value;
      }
    });

    return output;
  }

  getMap(): any {
    return this.map;
  }

  popAnnotation(uID): void {
    const toc = this.toc$.value;
    let i = 0;
    toc.forEach(value => {
      if (value['uID'] === uID) {
        toc.splice(i, 1);
      }
      i += 1;
    });
    this.toc$.next(toc);
  }

  pushToc(newTiem): void {
    const toc = this.toc$.value;
    toc.push(newTiem);
    this.toc$.next(toc);
  }

  setMap(inMap): void {
    this.map = inMap;
  }

  updateAction(newAction): void {
    if (this.action.value.length > 0) {
      if (this.action.value === newAction) {
        this.action.next('');
      } else {
        this.action.next('');
        this.action.next(newAction);
      }
    } else {
      this.action.next(newAction);
    }
  }
  updateDEM(newDEM): void {
    this.dem.next(newDEM);
  }
  updateDemOpacity(mewDemOpacity): void {
    this.demOpacity$.next(mewDemOpacity);
  }
}
