import { Injectable, NgZone } from '@angular/core';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';

import { WebgisService } from './../webgis.service';
import { MeasurementService } from './../logic/measurement.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class DrawObjectService {
  activePath = 'length';
  clickAction: any;
  map: any;
  pathHeight = [];
  pathLength = [];
  pathWidth = [];
  polylineHeight: any;
  polylineLength: any;
  polylineWidth: any;
  rightclickAction: any;

  constructor(
    private API: ApiService,
    public webgisService: WebgisService,
    private router: Router,
    private _ngZone: NgZone,
    private measurementService: MeasurementService
  ) {
    // do Something
  }

  addObject(): void {
    const polylineHeight = new google.maps.Polyline({
      path: this.pathHeight,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    const polylineLength = new google.maps.Polyline({
      path: this.pathLength,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    const polylineWidth = new google.maps.Polyline({
      path: this.pathWidth,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    polylineHeight.setMap(this.map);
    polylineLength.setMap(this.map);
    polylineWidth.setMap(this.map);

    polylineHeight['path'] = {};
    polylineLength['path'] = {};
    polylineWidth['path'] = {};

    const object = {height: polylineHeight, length: polylineLength, width: polylineWidth};

    this.measurementService.getObjectsLengths(object);

    object['name'] = 'NameObject';
    object['uID'] = Math.floor(Math.random() * Math.floor(999999999));
    object['type'] = 'Object';

    this.API.addObject(object['uID'], object['name']).subscribe();

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(object);
    });

    // Events
    // Select Polygon
    polylineHeight.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });
    polylineLength.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });
    polylineWidth.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });

    // Vertex moved
    google.maps.event.addListener(polylineHeight.getPath(), 'set_at', evt => {
      this.measurementService.getHeightDiff(polylineHeight, object);
    });
    google.maps.event.addListener(polylineLength.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polylineLength, object);
    });
    google.maps.event.addListener(polylineWidth.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polylineWidth, object);
    });

    // Vertex added
    google.maps.event.addListener(polylineHeight.getPath(), 'insert_at', evt => {
      alert('work in process');
    });
    google.maps.event.addListener(polylineHeight.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polylineHeight, object);
    });
    google.maps.event.addListener(polylineHeight.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polylineHeight, object);
    });
    this.pathHeight = [];
    this.pathLength = [];
    this.pathWidth = [];

    this._ngZone.runTask(() => {
      this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
    });
  }

  clickEvent(evt): void {
    if (this.activePath === 'length') {
      this.clickEventLength(evt);
    } else if (this.activePath === 'width') {
      this.clickEventWidth(evt);
    } else if (this.activePath === 'height') {
      this.clickEventHeight(evt);
    }
    if (this.pathHeight.length === 2) {
      this.addObject();
      this.webgisService.updateAction('drawObject');
    }
  }

  clickEventHeight(evt): void {
    if (typeof this.polylineHeight !== 'undefined') {
      this.polylineHeight.setMap(null);
    }
    this.pathHeight.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
    this.polylineHeight = new google.maps.Polyline({
      path: this.pathHeight,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    this.polylineHeight.setMap(this.map);
  }

  clickEventLength(evt): void {
    if (typeof this.polylineLength !== 'undefined') {
      this.polylineLength.setMap(null);
    }
    this.pathLength.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
    this.polylineLength = new google.maps.Polyline({
      path: this.pathLength,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    this.polylineLength.setMap(this.map);
  }

  clickEventWidth(evt): void {
    if (typeof this.polylineWidth !== 'undefined') {
      this.polylineWidth.setMap(null);
    }
    this.pathWidth.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
    this.polylineWidth = new google.maps.Polyline({
      path: this.pathWidth,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    this.polylineWidth.setMap(this.map);
  }

  disableDrawing(): void {
    this.map.setOptions({draggableCursor: ''});
    google.maps.event.removeListener(this.clickAction);
    google.maps.event.removeListener(this.rightclickAction);

    this.pathHeight = [];
    this.pathLength = [];
    this.pathWidth = [];

    if (typeof this.polylineHeight !== 'undefined') {
      this.polylineHeight.setMap(null);
    }
    if (typeof this.polylineLength !== 'undefined') {
      this.polylineLength.setMap(null);
    }
    if (typeof this.polylineWidth !== 'undefined') {
      this.polylineWidth.setMap(null);
    }
    this.activePath = 'length';
  }

  enableDrawing(): void {
    this.map.setOptions({draggableCursor: 'crosshair'});
    this.clickAction = this.map.addListener('click', evt => this.clickEvent(evt));
    this.rightclickAction = this.map.addListener('rightclick', evt => this.rightclickEvent());
  }

  loadObject(pathLength, pathWidth, pathHeight, name, color, comment?, uID?, objMeasurment?): void {
    const polylineHeight = new google.maps.Polyline({
      path: pathHeight,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    const polylineLength = new google.maps.Polyline({
      path: pathLength,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    const polylineWidth = new google.maps.Polyline({
      path: pathWidth,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    polylineHeight.setMap(this.map);
    polylineLength.setMap(this.map);
    polylineWidth.setMap(this.map);

    polylineHeight['path'] = pathHeight;
    polylineLength['path'] = pathLength;
    polylineWidth['path'] = pathWidth;

    polylineLength.length3D = +objMeasurment[0];
    polylineWidth.length3D = +objMeasurment[1];
    polylineHeight.heightDiff = +objMeasurment[2];

    const object = {height: polylineHeight, length: polylineLength, width: polylineWidth};
    //this.measurementService.getObjectsLengths(object);

    object['name'] = name;
    object['comment'] = comment;
    // object['preview'] = preview;
    object['uID'] = uID;
    object['type'] = 'Object';

    this.measurementService.calcVolume(object);

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(object);
    });

    // Events
    // Select Polygon
    polylineHeight.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });
    polylineLength.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });
    polylineWidth.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', object['uID']] } }]);
      });
    });

    // Vertex moved
    google.maps.event.addListener(polylineHeight.getPath(), 'set_at', evt => {
      this.measurementService.getHeightDiff(polylineHeight, object);
    });
    google.maps.event.addListener(polylineLength.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polylineLength, object);
    });
    google.maps.event.addListener(polylineWidth.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polylineWidth, object);
    });

    // Vertex added
    google.maps.event.addListener(polylineHeight.getPath(), 'insert_at', evt => {
      alert('work in process');
    });
    google.maps.event.addListener(polylineLength.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polylineLength, object);
    });
    google.maps.event.addListener(polylineWidth.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polylineWidth, object);
    });
  }

  rightclickEvent(): void {
    if (this.activePath === 'length' && this.pathLength.length > 1) {
      this.activePath = 'width';
    }
    if (this.activePath === 'width' && this.pathWidth.length > 1) {
      this.activePath = 'height';
    }
  }

  setMap(inputMap): void {
    this.map = inputMap;
  }
}
