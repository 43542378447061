import { Injectable } from '@angular/core';
import { AgmMap } from '@agm/core';

import { DrawMarkerService } from './draw-marker.service';
import { DrawPolygonService } from './draw-polygon.service';
import { DrawPolylineService } from './draw-polyline.service';
import { DrawObjectService } from './draw-object.service';

@Injectable({
  providedIn: 'root'
})
export class DrawService {
  map: any;

  constructor(
    public drawMarkerService: DrawMarkerService,
    public drawPolygonService: DrawPolygonService,
    public drawPolylineService: DrawPolylineService,
    public drawObjectService: DrawObjectService
  ) {
    // do Something
  }

  setMap(inputMap): void {
    this.map = inputMap;
    this.drawMarkerService.setMap(inputMap);
    this.drawPolygonService.setMap(inputMap);
    this.drawPolylineService.setMap(inputMap);
    this.drawObjectService.setMap(inputMap);
  }
}
