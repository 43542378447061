import { Injectable, NgZone } from '@angular/core';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';

import { WebgisService } from './../webgis.service';
import { MeasurementService } from './../logic/measurement.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class DrawPolygonService {
  clickAction: any;
  map: any;
  paths = [];
  polygon: any;
  rightclickAction: any;

  constructor(
    private API: ApiService,
    public webgisService: WebgisService,
    private router: Router,
    private _ngZone: NgZone,
    private measurementService: MeasurementService
  ) {
    // do Something
  }

  addPolygon(inputPath): void {
    const polygon = new google.maps.Polygon({
      paths: inputPath,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'rgb(0, 187, 211)',
      fillOpacity: 0.35
    });

    polygon['name'] = 'NamePolygon';
    polygon['uID'] = Math.floor(Math.random() * Math.floor(999999999));
    polygon['type'] = 'Polygon';
    polygon['path'] = {};
    this.measurementService.getArea(polygon);
    this.measurementService.getLength(polygon);

    this.API.addPolygon(this.map.flightid, polygon['uID'], polygon['name']).subscribe();

    // Events
    // Select Polygon
    polygon.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', polygon['uID']] } }]);
      });
    });

    polygon.setMap(this.map);
    // Vertex moved
    google.maps.event.addListener(polygon.getPath(), 'set_at', evt => {
      this.measurementService.getArea(polygon);
      this.measurementService.getLength(polygon);
    });

    // Vertex added
    google.maps.event.addListener(polygon.getPath(), 'insert_at', evt => {
      this.measurementService.getArea(polygon);
      this.measurementService.getLength(polygon);
    });

    // move directly to the new Annotation
    this.disableDrawing();
    this._ngZone.runTask(() => {
      this.router.navigate(['', { outlets: { detail: ['annotation', polygon['uID']] } }]);
    });

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(polygon);
    });
  }

  clickEvent(evt): void {
    if (typeof this.polygon !== 'undefined') {
      this.polygon.setMap(null);
    }
    this.paths.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
    this.polygon = new google.maps.Polygon({
      paths: this.paths,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'rgb(0, 187, 211)',
      fillOpacity: 0.35
    });
    this.polygon.setMap(this.map);
  }

  disableDrawing(): void {
    this.map.setOptions({draggableCursor: ''});
    google.maps.event.removeListener(this.clickAction);
    google.maps.event.removeListener(this.rightclickAction);
  }

  enableDrawing(): void {
    this.map.setOptions({draggableCursor: 'crosshair'});
    this.clickAction = this.map.addListener('click', evt => this.clickEvent(evt));
    this.rightclickAction = this.map.addListener('rightclick', evt => this.rightclickEvent());
  }

  loadPolygon(inputPath, name, color, comment, uID?): void {
    const polygon = new google.maps.Polygon({
      paths: inputPath,
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35
    });

    polygon['name'] = name;
    polygon['comment'] = comment;
    polygon['uID'] = uID;
    polygon['type'] = 'Polygon';
    polygon['path'] = {};

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(polygon);
    });

    // Events
    // Select Polygon
    polygon.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', polygon['uID']] } }]);
      });
    });

    polygon.setMap(this.map);
    // Vertex moved
    google.maps.event.addListener(polygon.getPath(), 'set_at', evt => {
      this.measurementService.getArea(polygon);
      this.measurementService.getLength(polygon);
    });

    // Vertex added
    google.maps.event.addListener(polygon.getPath(), 'insert_at', evt => {
      this.measurementService.getArea(polygon);
      this.measurementService.getLength(polygon);
    });


    this.measurementService.getArea(polygon);
    this.measurementService.getLength(polygon);
  }

  rightclickEvent(): void {
    if (this.paths.length > 2) {
      this.addPolygon(this.paths);
      this.paths = [];
    }
    if (typeof this.polygon !== 'undefined') {
      this.polygon.setMap(null);
      this.paths = [];
    }
    this.webgisService.updateAction('drawPolygon');
  }

  // constructor() { }
  setMap(inputMap): void {
    this.map = inputMap;
  }
}
