import { Injectable } from '@angular/core';

import { DrawService } from './draw.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class LoadAnnotationsService {
  constructor(
    private drawService: DrawService,
    private API: ApiService
  ) { }

  loadAnnotation(flightid): void {
    // coordinates and UID has some problems
    const getEntries = this.API.getEntries(flightid).subscribe((data: any) => {
      data.forEach(annotation => {
        if (annotation.type === 'Point') {
          // Coord cleanup
          const coord = annotation.coordinates.replace('[', '').replace(']', '').split(',');
          const latLng = {lat: +coord[1], lng: +coord[0]};

          this.drawService.drawMarkerService.loadMarker(latLng, annotation.name,
            annotation.color, annotation.comment, +annotation.uid, annotation.preview, coord[2],[annotation.objLength,annotation.objWidth,annotation.objHeight], annotation.report);
        } else if (annotation.type === 'LineString') {
          const path = [];
          const points = [];
          // string Points
          const strPoints = annotation.coordinates.substring(2, annotation.coordinates.length - 2).split('],[');
          strPoints.forEach(strPoint => {
            points.push(strPoint.split(','));
            //path.push({lat: point, lng: +point[0]});
          });
          // Path
          points.forEach(point => {
            path.push({lat: +point[1], lng: +point[0]});
          });
          this.drawService.drawPolylineService.loadPolyline(path, annotation.name, annotation.color, annotation.comment,
           +annotation.uid);
          } else if (annotation.type === 'Polygon') {
            const path = [];
            const points = [];
            // string Points
            const strPoints = annotation.coordinates.substring(2, annotation.coordinates.length - 2).split('],[');
            strPoints.forEach(strPoint => {
              points.push(strPoint.split(','));
            });
            // Path
            points.forEach(point => {
              path.push({lat: +point[1], lng: +point[0]});
            });
            this.drawService.drawPolygonService.loadPolygon(path, annotation.name, annotation.color, annotation.comment,
              +annotation.uid);
          } else if (annotation.type === 'MultiLineString') {
            try {
              const pathLength = [];
              const pathWidth = [];
              const pathHeight = [];
              const pointsLength = [];
              const pointsWidth = [];
              const pointsHeight = [];
              // string Points
              const strPaths = annotation.coordinates.substring(3, annotation.coordinates.length - 3).split(']],[[');
              // Length
              strPaths[0].split('],[').forEach(strPoint => {
                pointsLength.push(strPoint.split(','));
              });
              pointsLength.forEach(point => {
                pathLength.push({lat: +point[1], lng: +point[0], alt: +point[2]});
              });
              // Width
              strPaths[1].split('],[').forEach(strPoint => {
                pointsWidth.push(strPoint.split(','));
              });
              pointsWidth.forEach(point => {
                pathWidth.push({lat: +point[1], lng: +point[0], alt: +point[2]});
              });
              // Height
              strPaths[2].split('],[').forEach(strPoint => {
                pointsHeight.push(strPoint.split(','));
              });
              pointsHeight.forEach(point => {
                pathHeight.push({lat: +point[1], lng: +point[0], alt: +point[2]});
              });
              this.drawService.drawObjectService.loadObject(pathLength, pathWidth, pathHeight, annotation.name, annotation.color,
                annotation.comment, +annotation.uid, [annotation.objLength, annotation.objWidth, annotation.objHeight]);
            }
            catch(e) {
              console.log('Object could not be loaded');
            }
            /*
            strPaths.forEach(strPath => {
              strPath.split('],[').forEach(strPoint => {
                points.push(strPoint.split(','));
              });
            });
            */
            /*
            const strPoints = annotation.coordinates.substring(2, annotation.coordinates.length - 2).split('],[');
            strPoints.forEach(strPoint => {
              points.push(strPoint.split(','));
            });
            // Path
            points.forEach(point => {
              path.push({lat: +point[1], lng: +point[0]});
            });
            this.drawService.drawPolygonService.loadPolygon(path, annotation.name, annotation.color, annotation.comment,
              +annotation.uid);
            */
          }
      });
    });
  }
  /*
  loadAnnotation(): void {
    mongoFeatures.forEach(annotation => {
      if (annotation.geometry.type === 'Point') {
        const latLng = {lat: annotation.geometry.coordinates[1], lng: annotation.geometry.coordinates[0]};
        this.drawService.drawMarkerService.loadMarker(latLng, annotation.properties.name,
            annotation.properties.color, annotation.properties.comment, annotation.properties.preview, annotation.properties.uID);
      } else if (annotation.geometry.type === 'MultiPoint') {
          const latLng = {lat: annotation.geometry.coordinates[0][1], lng: annotation.geometry.coordinates[0][0]};
          this.drawService.drawMarkerService.loadObjMarker(latLng, annotation.properties.name,
              annotation.properties.color, annotation.properties.comment, annotation.properties.preview, annotation.properties.uID,
              annotation.properties.objLength, annotation.properties.objWidth, annotation.properties.objHeight, annotation.properties.report);
        } else if (annotation.geometry.type === 'LineString') {
        const path = [];
        // tslint:disable-next-line: forin
        for (const coord in annotation.geometry.coordinates) {
          path.push({lat: annotation.geometry.coordinates[coord][1], lng: annotation.geometry.coordinates[coord][0]});
        }
        this.drawService.drawPolylineService.loadPolyline(path, annotation.properties.name,
            annotation.properties.color, annotation.properties.comment);
      } else if (annotation.geometry.type === 'Polygon') {
        const path = [];
        // Change for GeoJSON
        // tslint:disable-next-line: forin
        for (const coord in annotation.geometry.coordinates) {
          path.push({lat: annotation.geometry.coordinates[coord][1], lng: annotation.geometry.coordinates[coord][0]});
        }
        path.pop();

        this.drawService.drawPolygonService.loadPolygon(path, annotation.properties.name,
           annotation.properties.color, annotation.properties.comment);
      } else if (annotation.geometry.type === 'MultiLineString') {
        if (annotation.geometry.coordinates.length === 3) {
          const pathLength = [];
          const pathWidth = [];
          const pathHeight = [];
          // tslint:disable-next-line: forin
          for (const coord in annotation.geometry.coordinates[0]) {
            pathLength.push({lat: annotation.geometry.coordinates[0][coord][1], lng: annotation.geometry.coordinates[0][coord][0]});
          }
          // tslint:disable-next-line: forin
          for (const coord in annotation.geometry.coordinates[1]) {
            pathWidth.push({lat: annotation.geometry.coordinates[1][coord][1], lng: annotation.geometry.coordinates[1][coord][0]});
          }
          // tslint:disable-next-line: forin
          for (const coord in annotation.geometry.coordinates[2]) {
            pathHeight.push({lat: annotation.geometry.coordinates[2][coord][1], lng: annotation.geometry.coordinates[2][coord][0]});
          }
          this.drawService.drawObjectService.loadObject(pathLength, pathWidth, pathHeight,
            annotation.properties.name, annotation.properties.color, annotation.properties.comment, annotation.properties.preview, annotation.properties.uID);
        }
      }
    });
  }
*/
}
