import { Injectable, NgZone } from '@angular/core';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';

import { WebgisService } from './../webgis.service';
import { MeasurementService } from './../logic/measurement.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class DrawPolylineService {
  clickAction: any;
  map: any;
  path = [];
  polyline: any;
  rightclickAction: any;

  constructor(
    private API: ApiService,
    public webgisService: WebgisService,
    private router: Router,
    private _ngZone: NgZone,
    private measurementService: MeasurementService
  ) {
    // do Something
  }
  addPolyline(inputPath): void {
    const polyline = new google.maps.Polyline({
      path: inputPath,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    polyline.setMap(this.map);

    polyline['name'] = 'NamePolyline';
    polyline['uID'] = Math.floor(Math.random() * Math.floor(999999999));
    polyline['type'] = 'Polyline';

    this.API.addLine(this.map.flightid, polyline['uID'], polyline['name']).subscribe();

    polyline['path'] = {};
    this.measurementService.getLength(polyline);

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(polyline);
    });

    // Events
    // Select Polyline
    polyline.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', polyline['uID']] } }]);
      });
    });

    // Vertex moved
    google.maps.event.addListener(polyline.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polyline);
    });

    // Vertex added
    google.maps.event.addListener(polyline.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polyline);
    });

    // move directly to the new Annotation
    this.disableDrawing();
    this._ngZone.runTask(() => {
      this.router.navigate(['', { outlets: { detail: ['annotation', polyline['uID']] } }]);
    });
  }

  clickEvent(evt): void {
    if (typeof this.polyline !== 'undefined') {
      this.polyline.setMap(null);
    }
    this.path.push({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
    this.polyline = new google.maps.Polyline({
      path: this.path,
      geodesic: true,
      strokeColor: 'rgb(0, 187, 211)',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    this.polyline.setMap(this.map);
  }

  disableDrawing(): void {
    this.map.setOptions({draggableCursor: ''});
    google.maps.event.removeListener(this.clickAction);
    google.maps.event.removeListener(this.rightclickAction);
  }

  enableDrawing(): void {
    this.map.setOptions({draggableCursor: 'crosshair'});
    this.clickAction = this.map.addListener('click', evt => this.clickEvent(evt));
    this.rightclickAction = this.map.addListener('rightclick', evt => this.rightclickEvent());
  }

  loadPolyline(inputPath, name, color, comment?, uID?, preview?): void {
    const polyline = new google.maps.Polyline({
      path: inputPath,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    polyline.setMap(this.map);

    polyline['name'] = name;
    polyline['uID'] = uID;
    polyline['type'] = 'Polyline';
    polyline['comment'] = comment;

    polyline['path'] = {};
    this.measurementService.getLength(polyline);

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(polyline);
    });

    // Events
    // Select Polyline
    polyline.addListener('click', test => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', polyline['uID']] } }]);
      });
    });

    // Vertex moved
    google.maps.event.addListener(polyline.getPath(), 'set_at', evt => {
      this.measurementService.getLength(polyline);
    });

    // Vertex added
    google.maps.event.addListener(polyline.getPath(), 'insert_at', evt => {
      this.measurementService.getLength(polyline);
    });
  }

  rightclickEvent(): void {
    if (this.path.length > 1) {
      this.addPolyline(this.path);
      this.path = [];
    }
    if (typeof this.polyline !== 'undefined') {
      this.polyline.setMap(null);
    }
    this.webgisService.updateAction('drawPolyline');
  }

  setMap(inputMap): void {
    this.map = inputMap;
  }
}
