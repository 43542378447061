import { Injectable, NgZone } from '@angular/core';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';

import { WebgisService } from './../webgis.service';
import { MeasurementService } from './../logic/measurement.service';
import { ApiService } from './../../webgishelper/mock-service/api.service';

declare const google: any;
@Injectable({
  providedIn: 'root'
})
export class DrawMarkerService {
  clickAction: any;
  map: any;
  rightclickAction: any;

  constructor(
    private API: ApiService,
    public webgisService: WebgisService,
    private router: Router,
    private _ngZone: NgZone,
    private measurementService: MeasurementService
  ) {
    // do Something
  }

  addMarker(latLng): void {
    // https://stackoverflow.com/questions/2472957/how-can-i-change-the-color-of-a-google-maps-marker
    const markerImage = {
        path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
        anchor: new google.maps.Point(12, 23),
        fillOpacity: 1,
        fillColor: 'rgb(0, 187, 211)',
        strokeWeight: 2,
        strokeColor: 'white',
        scale: 2
    };
    const marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: markerImage
    });
    marker['name'] = 'NameMarker';
    marker['uID'] = Math.floor(Math.random() * Math.floor(999999999));
    marker['type'] = 'Marker';

    this.API.addMarker(this.map.flightid, marker['uID'], marker['name'], [marker.position.lng(), marker.position.lat()]).subscribe();

    this.measurementService.getHeightMarker(marker);

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(marker);
    });

    marker.addListener('click', () => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', marker['uID']] } }]);
      });
    });
    marker.addListener('dragstart', evt => {
      marker.setAnimation(null);
    });
    marker.addListener('dragend', evt => {
      this.measurementService.getHeightMarker(marker);
      marker.setAnimation(google.maps.Animation.BOUNCE);
    });

    // move directly to the new Annotation
    this.webgisService.updateAction('');
    this._ngZone.runTask(() => {
      this.router.navigate(['', { outlets: { detail: ['annotation', marker['uID']] } }]);
    });
  }

  clickEvent(evt): void {
    this.addMarker({lat: evt.latLng.lat(), lng: evt.latLng.lng()});
  }

  disableDrawing(): void {
    this.map.setOptions({draggableCursor: ''});
    google.maps.event.removeListener(this.clickAction);
    google.maps.event.removeListener(this.rightclickAction);
  }

  enableDrawing(): void {
    this.map.setOptions({draggableCursor: 'crosshair'});
    this.clickAction = this.map.addListener('click', evt => this.clickEvent(evt));
    this.rightclickAction = this.map.addListener('rightclick', evt => this.rightclickEvent());
  }

  loadMarker(latLng, name, color, comment?, uID?, preview?, height?, objMeasurements?, report?): void {
    const markerImage = {
      path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
      anchor: new google.maps.Point(12, 23),
      fillOpacity: 1,
      fillColor: color,
      strokeWeight: 2,
      strokeColor: 'white',
      scale: 2
    };
    const marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: markerImage
    });
    marker['name'] = name;
    marker['uID'] = uID;
    if (preview.length > 0) {
      marker['preview'] = preview;
    }
    if (report.length > 0) {
      marker['report'] = report;
    }
    marker['type'] = 'Marker';
    marker['comment'] = comment;
    marker['height'] = height;

    // Object
    if (objMeasurements[0].length > 0) {
      marker['objLength'] = objMeasurements[0];
    }
    if (objMeasurements[1].length > 0) {
      marker['objWidth'] = objMeasurements[1];
    }
    if (objMeasurements[2].length > 0) {
      marker['objHeight'] = objMeasurements[2];
    }
    if (objMeasurements[0].length > 0 && objMeasurements[1].length > 0 && objMeasurements[2].length > 0) {
      marker['objVolume'] = Math.round(objMeasurements[0] * objMeasurements[1] * objMeasurements[2] * 1000) / 1000;
    }

    this._ngZone.runTask(() => {
      this.webgisService.pushToc(marker);
    });

    marker.addListener('click', () => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', marker['uID']] } }]);
      });
    });
    marker.addListener('dragstart', evt => {
      marker.setAnimation(null);
    });
    marker.addListener('dragend', evt => {
      marker['height'] = this.measurementService.getHeightMarker(marker);
      marker.setAnimation(google.maps.Animation.BOUNCE);
    });
  }



  loadObjMarker(latLng, name, color, comment?, preview?, uID?, objLength?, objWidth?, objheight?, reportUrl?): void {
    const markerImage = {
      path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
      anchor: new google.maps.Point(12, 23),
      fillOpacity: 1,
      fillColor: color,
      strokeWeight: 2,
      strokeColor: 'white',
      scale: 2
    };
    const marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: markerImage
    });
    marker['name'] = name;
    marker['uID'] = uID;
    marker['preview'] = preview;
    marker['type'] = 'ObjMarker';
    marker['comment'] = comment;
    marker['report'] = reportUrl;
    marker['objLength'] = objLength;
    marker['objWidth'] = objWidth;
    marker['objHeight'] = objheight;
    marker['objVolume'] = Math.round(objLength * objWidth * objheight * 1000) / 1000;
    this._ngZone.runTask(() => {
      this.webgisService.pushToc(marker);
    });

    marker.addListener('click', () => {
      this._ngZone.runTask(() => {
        this.router.navigate(['', { outlets: { detail: ['annotation', marker['uID']] } }]);
      });
    });
    marker.addListener('dragstart', evt => {
      marker.setAnimation(null);
    });
    marker.addListener('dragend', evt => {
      marker['height'] = this.measurementService.getHeightMarker(marker);
      marker.setAnimation(google.maps.Animation.BOUNCE);
    });
  }

  rightclickEvent(): void {
    this.webgisService.updateAction('drawMarker');
  }

  setMap(inputMap): void {
    this.map = inputMap;
  }
}
