import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgmMap } from '@agm/core';
// WebGIS Imports

// Featue Imports
import { mongoMap } from './../webgishelper/mock-service/json/map';
import { WebgisService } from './webgis.service';
import { DrawService } from './logic/draw.service';
import { LoadAnnotationsService } from './logic/load-annotations.service';
import { MeasurementService } from './logic/measurement.service';

declare var google: any;
@Component({
  selector: 'app-webgis',
  templateUrl: './webgis.component.html',
  styleUrls: ['./webgis.component.scss']
})
export class WebgisComponent {
  actions = {};
  @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;
  demLayer: any;
  flightid: any;
  lastAction: string;
  lat = 48.49237854080266;
  lng = 11.925475170552133;
  map: any;
  tocElements: number;

  constructor(
    private webgisService: WebgisService,
    private drawService: DrawService,
    private loadAnnotations: LoadAnnotationsService,
    private router: Router,
    private measurementService: MeasurementService
  ) {
    this.actions['action'] = this.webgisService.actionService.subscribe(actionService => { this.toggleAction(actionService); });
    this.actions['demOpacity'] = this.webgisService.demOpacity$.subscribe(demOpacity => { this.updateDemOpacity(demOpacity); });
    this.actions['dem'] = this.webgisService.dem.subscribe(dem => { this.toggleDem(dem); });
  }

  getTMS(): any {
    const TILE_URL = 'https://fftestgdal.s3.eu-central-1.amazonaws.com/ff4190/';

    const layer = new google.maps.ImageMapType({
      getTileUrl: function(coord, zoom): any {

        const ymax = 1 << zoom;
        const y = ymax - coord.y - 1;

        return `${TILE_URL}${zoom}_${coord.x}_${y}.png`;
      },
      tileSize: new google.maps.Size(256, 256),
      minZoom: 1,
      maxZoom: 20
    });

    return layer;
  }

  getWMS(map, serverURL, layer, opacity = 1): any {
    // SOURCE: https://stackoverflow.com/questions/8354163/google-map-api-3-wms
    const wmsLayer = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        const proj = map.getProjection();
        const zfactor = Math.pow(2, zoom);
        // get Long Lat coordinates
        const top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
        const bot = proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor));

        // corrections for the slight shift of the SLP (mapserver)
        const deltaX = 0;
        const deltaY = 0;

        // create the Bounding box string
        let bbox = `${(top.lng() + deltaX)},`;
        bbox += `${(bot.lat() + deltaY)},`;
        bbox += `${(bot.lng() + deltaX)},`;
        bbox += `${(top.lat() + deltaY)}`;

        let url = serverURL;          // WMS Server URL
        url += 'ows?&REQUEST=GetMap';     // WMS operation
        url += '&SERVICE=WMS';        // WMS service
        url += '&VERSION=1.1.1';      // WMS version
        url += `&LAYERS=${layer}`;    // ' + 'ff-99999:DEM'; // WMS layers
        url += '&FORMAT=image/png' ;  // WMS format
        url += '&TRANSPARENT=TRUE';   // set transparency
        url += '&SRS=EPSG:4326';      // set WGS84
        url += `&BBOX=${bbox}`;       // set bounding box
        url += '&WIDTH=256';          // tile size in google
        url += '&HEIGHT=256';

        return url;
      },
      tileSize: new google.maps.Size(256, 256),
      isPng: true,
      opacity
    });

    return wmsLayer;
  }

  loadTOC(toc): void {
    // tslint:disable-next-line: forin
    for (const annotation in toc) {
      if (toc[annotation].type !== 'Object') {
        toc[annotation].setMap(this.map);
      } else if (toc[annotation].type === 'Object') {
        toc[annotation].height.setMap(this.map);
        toc[annotation].length.setMap(this.map);
        toc[annotation].width.setMap(this.map);
      }
    }
  }

  ngOnDestroy(): void {
    this.actions['action'].unsubscribe();
    this.actions['demOpacity'].unsubscribe();
    this.actions['dem'].unsubscribe();
  }

  ngAfterViewInit(): void {
    //load TOC elements
    this.agmMap.mapReady.subscribe(map => {
      const tmpFlightid = this.router.url.split('(')[0].split('/')[2];
      if (tmpFlightid.includes('-')) {
        this.flightid = tmpFlightid.split('-')[0];
      } else {
        this.flightid = tmpFlightid;
      }
      this.map = map;
      this.drawService.setMap(this.map);
      // SOURCE: https://stackoverflow.com/questions/48865595/is-there-a-way-to-set-the-bounds-and-zoom-level-in-agm-map
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(new google.maps.LatLng(mongoMap[this.flightid]['extent']['ymin'], mongoMap[this.flightid]['extent']['xmin']));
      bounds.extend(new google.maps.LatLng(mongoMap[this.flightid]['extent']['ymax'], mongoMap[this.flightid]['extent']['xmax']));
      map.fitBounds(bounds);
      this.map['flightid'] = this.flightid;
      this.measurementService.addFlightid(this.flightid);

      map.overlayMapTypes.push(this.getWMS(map, mongoMap[this.flightid]['geoserver']['serverURL'],
          `${mongoMap[this.flightid]['geoserver']['workspace']}:${mongoMap[this.flightid]['geoserver']['ortho']}`));

      this.demLayer = this.getWMS(map, mongoMap[this.flightid]['geoserver']['serverURL'],
        `${mongoMap[this.flightid]['geoserver']['workspace']}:${mongoMap[this.flightid]['geoserver']['dem']}`, 0.5);
     //map.overlayMapTypes.push(this.getTMS());
      this.webgisService.setMap(map);

      // Increase ZoomLevel
      // https://stackoverflow.com/questions/30136525/allow-further-zoom-on-google-maps-v3-satellite-view
      const zoomRangeModifier = map.__proto__.__proto__.__proto__;
      const originalSetFunc = zoomRangeModifier.set;
      const hijackedSetFunc = function(a, b) {
          if (a === 'maxZoom') {
              b = 30;
          }
          originalSetFunc.call(this, a, b);
      };
      zoomRangeModifier.set = hijackedSetFunc;

      // load TOC
      const load = this.webgisService.toc$.subscribe(toc => {
        this.loadTOC(toc);
        this.tocElements = toc.length;
      });
      load.unsubscribe();
      if (this.tocElements === 0) {
        this.loadAnnotations.loadAnnotation(this.flightid);
      }
    });
  }

  toggleAction(inputAction): void {
    if (inputAction === 'drawMarker') {
      this.actions['Marker'] = this.drawService.drawMarkerService.enableDrawing();
    } else if (inputAction === 'drawPolyline') {
      this.drawService.drawPolylineService.enableDrawing();
    } else if (inputAction === 'drawPolygon') {
      this.drawService.drawPolygonService.enableDrawing();
    } else if (inputAction === 'drawObject') {
      this.drawService.drawObjectService.enableDrawing();
    }
    if (this.lastAction === 'drawMarker') {
      this.drawService.drawMarkerService.disableDrawing();
    } else if (this.lastAction === 'drawPolyline') {
      this.drawService.drawPolylineService.disableDrawing();
    } else if (this.lastAction === 'drawPolygon') {
      this.drawService.drawPolygonService.disableDrawing();
    } else if (this.lastAction === 'drawObject') {
      this.drawService.drawObjectService.disableDrawing();
    }
    this.lastAction = inputAction;
  }
  toggleDem(inputDem): void {
    if (this.demLayer !== undefined) {
      (inputDem === '') ? this.map.overlayMapTypes.pop(this.demLayer) : this.map.overlayMapTypes.push(this.demLayer);
    }
  }

  updateDemOpacity(newDemOpacity): void {
    if (this.demLayer !== undefined) {
      this.demLayer.setOpacity(newDemOpacity / 100);
    }
  }

  fromPointToLatLng(point): any {

    return {
     lat: (2 * Math.atan(Math.exp((point.y - 128) / -(256 / (2 * Math.PI)))) -
            Math.PI / 2)/ (Math.PI / 180),
     lng:  (point.x - 128) / (256 / 360)
    };

 }
}
