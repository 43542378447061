import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './pages/webgishelper/header/header.component'; // WebGIS
import { MaterialModule } from './shared/helpers/material.module';
import { TocComponent } from './pages/webgis/toc/toc.component';
import { WebgisComponent } from './pages/webgis/webgis.component';
import { WebgisService } from './pages/webgis/webgis.service';
import { AnnotationComponent } from './pages/webgis/annotation/annotation.component';
import { ViewerComponent } from './pages/potree/viewer/viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    WebgisComponent,
    TocComponent,
    HeaderComponent,
    AnnotationComponent,
    ViewerComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBkyGiPqqEKI8gCYHOvXmmAgRVe273-leU'
    })
  ],
  providers: [WebgisService],
  bootstrap: [AppComponent]
})
export class AppModule {}
