import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as turf from '@turf/turf';

import { mongoMap } from './../../webgishelper/mock-service/json/map';
import { ApiService } from './../../webgishelper/mock-service/api.service';
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class MeasurementService {
  apiURL = 'http://www.server.com/api/';
  flightid: any;
  geoserver: any;
  constructor(
      private API: ApiService,
      private httpClient: HttpClient
    ) {}

  addFlightid(inputFlightid): any {
    this.flightid = inputFlightid;
    this.geoserver = mongoMap[this.flightid].geoserver;
  }

  calcAreaTriangle(listLength: any): number {
    // http://geomalgorithms.com/a01-_area.html#3D%20Polygons
    if (listLength.length === 3) {
      // 4a²b²-(a²+b²-c²)²
      const a = listLength[0];
      const b = listLength[1];
      const c = listLength[2];
      const a2 = Math.pow(a, 2);
      const b2 = Math.pow(b, 2);
      const c2 = Math.pow(c, 2);
      // console.log(listLength);
      // console.log('https://www.wolframalpha.com/input/?i='+listLength[0]+',+'+listLength[1]+',+'+listLength[2]+'+triangle')

      return Math.sqrt(a2 * b2 * 4 - Math.pow((a2 + b2 - c2), 2)) * 0.25;
    }
  }

  calcBounds(latLng): any {
    return `${latLng.lng() - 0.000000001}%2C${latLng.lat() - 0.000000001}%2C${latLng.lng()}%2C${latLng.lat()}`;
  }

  calcLength3D(pt1, pt2): number {
    const ptG1 = new google.maps.LatLng({lat: pt1['lat'], lng: pt1['lng']});
    const ptG2 = new google.maps.LatLng({lat: pt2['lat'], lng: pt2['lng']});

    const dist = google.maps.geometry.spherical.computeDistanceBetween(ptG1, ptG2);
    const height = Math.abs(pt1['alt'] - pt2['alt']);

    return Math.sqrt(Math.pow(dist, 2) + Math.pow(height, 2));
  }

  calcHeightDiff(annotation): void {
    let index = 0;
    let lastKey;
    // tslint:disable-next-line: forin
    for (const key in annotation['path']) {
      if (index !== 0) {
        const heightDiff = Math.abs(annotation['path'][key].alt - annotation['path'][lastKey].alt);
        annotation['heightDiff'] = Math.round(heightDiff * 100) / 100;
      } else {
        annotation['heightDiff'] = null;
      }
      lastKey = key;
      index += 1;
    }
  }

  calcVolume(object): void {
    if (typeof object !== 'undefined') {
      if (object.height.heightDiff !== 0 && object.length.length3D !== 0 && object.width.length3D !== 0){
        const volume = object.height.heightDiff * object.length.length3D * object.width.length3D;
        object['volume'] = Math.round(volume * 1000) / 1000;
      } else {
        object['volume'] = null;
      }
    }
  }

  getArea(annotation): void {
    annotation['area2D'] = Math.round(google.maps.geometry.spherical.computeArea(annotation.getPath()) * 1000) / 1000;
  }
  getArea3D(annotation): void {
    const vertices = [];
    // tslint:disable-next-line: forin
    for (const key in annotation['path']) {
      // inputPoly.push([vertices[0].lng, vertices[0].lat, vertices[0].alt]);
      vertices.push([annotation['path'][key].lng, annotation['path'][key].lat]);
    }
    vertices.push([annotation['path'][0].lng, annotation['path'][0].lat]);

    const poly = turf.polygon([vertices]);
    // console.log(poly['geometry']['coordinates'][0]);
    const triangles = this.getArea3DTriangles(poly, annotation);
    let totalArea3D = 0;
    // tslint:disable-next-line: forin
    for (const key in triangles) {
      const lengthList = [];
      for (let i = 0; i < 3; i++) {
        lengthList.push(this.calcLength3D(annotation['path'][triangles[key][i]], annotation['path'][triangles[key][i + 1]]));
      }
      totalArea3D += this.calcAreaTriangle(lengthList);
    }
    annotation['area3D'] = Math.round(totalArea3D * 1000) / 1000;
  }

  getArea3DTriangles(poly, annotation): any {
    let index = 0;
    const trianglesList = {};
    const triangles = turf.tesselate(poly);
    triangles['features'].forEach(feature => {
      trianglesList[index] = [];
      feature['geometry']['coordinates'][0].forEach(vertex => {
      // tslint:disable-next-line: forin
        for (const key in annotation['path']) {
          if (vertex[0] === annotation['path'][key]['lng']) {
            if (vertex[1] === annotation['path'][key]['lat']) {
              trianglesList[index].push(key);
            }
          }
        }
      });
      index += 1;
    });

    return trianglesList;
  }

  getHeightDiff(annotation, object?): void {
    annotation['todo'] = annotation.getPath().getArray().length;
    let index = 0;
    annotation.getPath().getArray().forEach(element => {
      this.getHeightVertex(annotation, index, true, object);

      index += 1;
    });
  }

  getHeightMarker(marker): void {
    const bounds = this.calcBounds(marker.position);
    const url = `${this.geoserver.serverURL}${this.geoserver.workspace}/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo` +
    `&QUERY_LAYERS=${this.geoserver.workspace}:${this.geoserver.dem}&` +
    `LAYERS=${this.geoserver.workspace}:${this.geoserver.dem}` +
    '&INFO_FORMAT=application/json&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101' +
    `&BBOX=${bounds}`;
    this.httpClient.get(url).subscribe(res => {
      if (res['features'].length > 0) {
        if (res['features'][0]['properties']['GRAY_INDEX'] !== -10000) {
          if (marker['height']  !== Math.round(res['features'][0]['properties']['GRAY_INDEX'] * 100) / 100) {
            marker['height'] = Math.round(res['features'][0]['properties']['GRAY_INDEX'] * 100) / 100;
            // API
            this.API.changeCoordinates(marker.uID, [marker.position.lng(), marker.position.lat(), marker['height']]).subscribe();
          }
        } else {
          // noValue
        }
      } else {
        // noFeatures
      }
    });
  }

  getHeightVertex(annotation, index, calcHeightDiff?, object?): void {
    const element = annotation.getPath().getArray()[index];
    let getHeight = false;

    if (typeof annotation['path'][index] !== 'undefined') {
      if (element.lat() !== annotation['path'][index]['lat']) {
        getHeight = true;
      } else {
        annotation['todo'] -= 1;
      }
    } else {
      getHeight = true;
    }

    if (getHeight) {
      const latLng = new google.maps.LatLng({lat: element.lat(), lng: element.lng()});
      const bounds = this.calcBounds(latLng);
      const url = `${this.geoserver.serverURL}${this.geoserver.workspace}/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo` +
      `&QUERY_LAYERS=${this.geoserver.workspace}:${this.geoserver.dem}&` +
      `LAYERS=${this.geoserver.workspace}:${this.geoserver.dem}` +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101' +
      `&BBOX=${bounds}`;
      this.httpClient.get(url).subscribe(res => {
        if (res['features'].length > 0) {
          if (res['features'][0]['properties']['GRAY_INDEX'] !== -10000) {
            const height = res['features'][0]['properties']['GRAY_INDEX'];
            annotation['todo'] -= 1;
            annotation['path'][index] = {lat: element.lat(), lng: element.lng(), alt: height};
            if (annotation['todo'] === 0) {
              if (calcHeightDiff) {
                this.calcHeightDiff(annotation);
                this.calcVolume(object);

                if (Object.keys(object.length['path']).length >= 2 &&
                Object.keys(object.width['path']).length >= 2 &&
                Object.keys(object.height['path']).length === 2) {
                  this.API.changeObject(object['uID'], [object.length['path'], object.width['path'], object.height['path']],
                      [object.length.length3D, object.width.length3D, object.height.heightDiff]).subscribe();
                }
              } else {
                this.getLength3D(annotation);
                this.calcVolume(object);
                if (annotation['type'] === 'Polygon') {
                  this.getArea3D(annotation);
                }
                // APICalls
                if (typeof object === 'undefined') {
                  this.API.changePath(annotation['uID'], annotation['path']).subscribe();
                } else {
                  if (Object.keys(object.length['path']).length >= 2 &&
                      Object.keys(object.width['path']).length >= 2 &&
                      Object.keys(object.height['path']).length === 2) {
                    this.API.changeObject(object['uID'], [object.length['path'], object.width['path'], object.height['path']],
                        [object.length.length3D, object.width.length3D, object.height.heightDiff]).subscribe();
                  }
                }
              }
            }
          } else {
            // noValue
            if (annotation['type'] === 'Polygon') {
              annotation['area3D'] = null;
            }
            annotation['length3D'] = null;
            annotation['path'][index] = {lat: element.lat(), lng: element.lng(), alt: null};
          }
        } else {
          // noFeatures
            if (annotation['type'] === 'Polygon') {
              annotation['area3D'] = null;
            }
            annotation['length3D'] = null;
            annotation['path'][index] = {lat: element.lat(), lng: element.lng(), alt: null};
        }
      });
    }
  }

  getLength(annotation, object?): void {
    annotation['length3D'] = 0;
    annotation['length2D'] = 0;
    annotation['todo'] = annotation.getPath().getArray().length;
    let index = 0;
    let lastLatLng;
    let totalLength2D = 0;
    annotation.getPath().getArray().forEach(element => {
      const latLng = new google.maps.LatLng({lat: element.lat(), lng: element.lng()});
      this.getHeightVertex(annotation, index, false, object);
      if (index !== 0) {
        totalLength2D += google.maps.geometry.spherical.computeDistanceBetween(lastLatLng, latLng);
      }
      lastLatLng = latLng;
      index += 1;
    });
    if (annotation['type'] === 'Polygon') {
      totalLength2D += google.maps.geometry.spherical.computeDistanceBetween(lastLatLng, annotation.getPath().getArray()[0]);
    }
    annotation['length2D'] = Math.round(totalLength2D * 100) / 100;
  }

  getLength3D(annotation): void {
    let index = 0;
    let lastKey;
    let totalLength3D = 0;
    // tslint:disable-next-line: forin
    for (const key in annotation['path']) {
      if (index !== 0) {
        // const heightDiff = annotation['path'][key]['alt'] - annotation['path'][lastKey]['alt'];
        totalLength3D += this.calcLength3D(annotation['path'][lastKey], annotation['path'][key]);
      }
      index += 1;
      lastKey = key;
    }
    if (annotation['type'] === 'Polygon') {
      totalLength3D += this.calcLength3D(annotation['path'][lastKey], annotation['path'][0]);
    }
    annotation['length3D'] = Math.round(totalLength3D * 100) / 100;
  }

  getObjectsLengths(object): void {
    if ('height' in object && 'length' in object && 'width' in object) {
      this.getHeightDiff(object.height, object);
      this.getLength(object.length, object);
      this.getLength(object.width, object);
    }
  }
}
